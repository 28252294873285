// src/pages/Customers.js

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaSearch, FaSave } from "react-icons/fa";
import '../style/pages/Customers.css';

function Customers() {
  const API_URL = process.env.REACT_APP_API_URL;

  const [searchTerm, setSearchTerm] = useState('');
  const [customers, setCustomers] = useState([]);
  const [total, setTotal] = useState(0); // Total de clientes
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 10; // Límite fijo de 10 filas por página
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const debounceTimeout = useRef(null);
  const isFirstRender = useRef(true); // Referencia para detectar la primera renderización

  const navigate = useNavigate();

  const columns = ['Email', 'Name', 'Purchases', 'Total Spend', 'Points Earned', 'Last Order'];

  // Ref for the table section
  const tableSectionRef = useRef(null);

  const fetchCustomers = async (page, search) => {
    setLoading(true);
    setError(null);
    try {
      const url = new URL(`${API_URL}/system_customers/`);
      url.searchParams.append('page', page);
      url.searchParams.append('limit', limit);
      if (search) {
        url.searchParams.append('email', search);
      }
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Error fetching customers: ${response.statusText}`);
      }
      const data = await response.json();
      setCustomers(data.data);
      setTotal(data.total);
    } catch (error) {
      console.error("Error fetching customers:", error);
      setError(error.message);
    }
    setLoading(false);
  };

  // useEffect para manejar cambios en currentPage
  useEffect(() => {
    fetchCustomers(currentPage, searchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  // useEffect para manejar cambios en searchTerm con debouncing
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return; // Salir del useEffect en la primera renderización
    }

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      setCurrentPage(1); // Reiniciar a la primera página
      fetchCustomers(1, searchTerm);
    }, 500);

    return () => {
      clearTimeout(debounceTimeout.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      setCurrentPage(1);
      fetchCustomers(1, searchTerm);
    }
  };

  const handleSearchIconClick = () => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    setCurrentPage(1);
    fetchCustomers(1, searchTerm);
  };

  // Calcular total de páginas
  const totalPages = Math.ceil(total / limit);

  // Generar números de página para mostrar
  const totalPageNumbersToShow = 10;
  let paginationNumbers = [];
  let startPageNumber = Math.max(1, currentPage - Math.floor(totalPageNumbersToShow / 2));
  let endPageNumber = startPageNumber + totalPageNumbersToShow - 1;

  if (endPageNumber > totalPages) {
    endPageNumber = totalPages;
    startPageNumber = Math.max(1, endPageNumber - totalPageNumbersToShow + 1);
  }

  for (let i = startPageNumber; i <= endPageNumber; i++) {
    paginationNumbers.push(i);
  }

  const handlePageClick = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      // Scroll back to the table section
      if (tableSectionRef.current) {
        tableSectionRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const handleRowClick = (customerId) => {
    navigate(`/customer/${customerId}`);
  };

  // Function to render placeholder rows
  const renderPlaceholderRows = () => {
    const placeholders = [];
    const rowsToRender = limit - customers.length;
    for (let i = 0; i < rowsToRender; i++) {
      placeholders.push(
        <tr key={`placeholder-${i}`} className="dashboard-placeholder-row">
          {columns.map((col, index) => (
            <td key={index}>&nbsp;</td>
          ))}
        </tr>
      );
    }
    return placeholders;
  };

  return (
    <div className="dashboard-container">
      <div className="dashboard-header">
        <h2 className="dashboard-title">Customers</h2>
        <span className="dashboard-subtitle">Search and manage loyalty & referrals customer accounts.</span>
      </div>

      <div className="dashboard-tile">
        <span className="dashboard-tile-text">Customers</span>
        <button className="dashboard-export-button">
          <FaSave className="dashboard-export-icon" />
          Export
        </button>
      </div>

      <div className="dashboard-search-container">
        <div className="dashboard-search-bar">
          <span className="dashboard-search-icon" onClick={handleSearchIconClick}><FaSearch /></span>
          <input
            type="text"
            className="dashboard-search-input"
            placeholder="Search by email address"
            value={searchTerm}
            onChange={handleSearchChange}
            onKeyPress={handleSearchKeyPress}
          />
        </div>
      </div>

      <div className="dashboard-table-section" ref={tableSectionRef}>
        <div className="dashboard-table-wrapper">
          <table className="dashboard-table">
            <thead>
              <tr>
                {columns.map((col, index) => (
                  <th key={index} className="dashboard-table-header">{col}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {loading ? (
                // Render placeholder rows during loading
                [...Array(limit)].map((_, index) => (
                  <tr key={`loading-${index}`} className="dashboard-placeholder-row">
                    {columns.map((col, idx) => (
                      <td key={idx}>&nbsp;</td>
                    ))}
                  </tr>
                ))
              ) : error ? (
                <tr>
                  <td colSpan={columns.length} className="dashboard-error">
                    Error: {error}
                  </td>
                </tr>
              ) : customers.length > 0 ? (
                <>
                  {customers.map((customer, index) => (
                    <tr
                      key={customer.id}
                      onClick={() => handleRowClick(customer.id)}
                      className="dashboard-table-row"
                    >
                      <td className="dashboard-table-cell email-link">
                        <span>{customer.email}</span>
                      </td>
                      <td className="dashboard-table-cell">{customer.name}</td>
                      <td className="dashboard-table-cell">{customer.purchases}</td>
                      <td className="dashboard-table-cell">{`$${customer.total_spend}`}</td>
                      <td className="dashboard-table-cell">{customer.points_earned}</td>
                      <td className="dashboard-table-cell">
                        {customer.last_order ? new Date(customer.last_order).toISOString().split('T')[0] : 'N/A'}
                      </td>
                    </tr>
                  ))}
                  {renderPlaceholderRows()}
                </>
              ) : (
                <>
                  <tr>
                    <td colSpan={columns.length} className="dashboard-no-results">
                      No results found.
                    </td>
                  </tr>
                  {renderPlaceholderRows()}
                </>
              )}
            </tbody>
          </table>
        </div>

        {/* Paginación */}
        <div className="dashboard-pagination">
          {currentPage > 1 && (
            <button
              className="dashboard-pagination-button"
              onClick={() => handlePageClick(currentPage - 1)}
            >
              &laquo;
            </button>
          )}
          {paginationNumbers.map(pageNumber => (
            <button
              key={pageNumber}
              className={`dashboard-pagination-button ${pageNumber === currentPage ? 'active' : ''}`}
              onClick={() => handlePageClick(pageNumber)}
            >
              {pageNumber}
            </button>
          ))}
          {currentPage < totalPages && (
            <button
              className="dashboard-pagination-button"
              onClick={() => handlePageClick(currentPage + 1)}
            >
              &raquo;
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Customers;
