import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Main from "./Main";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(

    <Main />

);

reportWebVitals();