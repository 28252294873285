import React from "react";
import { Routes, Route, Navigate} from "react-router-dom";
import LeftMenu from "./components/LeftMenu";
import ProtectedRoute from "./ProtectedRoute";
import routeConfig from "./RouteConfig";

function App() {
 

  return (
    <div className="main-layout">
      <LeftMenu />
      <div className="content-layout">
      <Routes>
          {routeConfig.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                <ProtectedRoute
                  element={route.element}
                />
              }
            />
          ))}
          <Route path="*" element={<Navigate to="/customers" />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;