import React from "react";
import {
    FaChartBar,
    FaUsers,
} from "react-icons/fa";
import Customers from "./pages/Customers";
import CustomerCard from "./pages/CustomerCard";
import AnalyticsRedemption from "./pages/analytics/AnalyticsRedemption";
import AnalyticsEarnings from "./pages/analytics/AnalyticsEarnings";


const routeConfig = [
    {
        path: "/customers",
        title: "Customers",
        element: Customers, 
        icon: <FaUsers />,
        submenu: [],
        showInMenu: true
    },

    {
        title: "Analytics",
        icon: <FaChartBar />,
        submenu: [
            {
                title: "Redemption Analytics",
                path: "/redemption-analytics",
                showInMenu: true,
            },
            {
                title: "Earning Analytics",
                path: "/earning-analytics",
                showInMenu: true,
            },
        ],
        showInMenu: true
    },
    


    // Agrega más rutas y submenús según sea necesario SUBMENUS !!!!!!!!

    {
        path: "/customer/:id",
        title: "Customer Card",
        element: CustomerCard, 
        showInMenu: false
    },

    // Analytics paths

    {
        path: "/redemption-analytics",
        title: "Redemption Analytics",
        element: AnalyticsRedemption, 
        showInMenu: true
    },
    {
        path: "/earning-analytics",
        title: "Earning Analytics",
        element: AnalyticsEarnings, 
        showInMenu: true
    }

    // Rutas dinámicas o adicionales


];

export default routeConfig;