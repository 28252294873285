// src/components/CustomerCard/AdjustPointsPopup.js

import React, { useState } from 'react';
import '../../style/components/CustomerCard/AdjustPointsPopup.css'; // Asegúrate de agregar estilos

const AdjustPointsPopup = ({ togglePopup, customerId }) => {
    const [action, setAction] = useState('add'); // default to 'add'
    const [points, setPoints] = useState('');
    const [description, setDescription] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        if (action === 'add') {
            // Validar entradas
            if (!points || !description) {
                setError('Por favor, completa todos los campos.');
                return;
            }

            // Preparar datos
            const payload = {
                date_created: new Date().toISOString().split('T')[0], // yyyy-mm-dd
                description: description,
                points: parseInt(points, 10),
            };

            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/system_customers/customer/${customerId}/rewards`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.detail || 'Error al crear la recompensa.');
                }
                setSuccess('Recompensa creada exitosamente.');
                // Opcional: refrescar datos o llamar a una función del padre para actualizar el estado
                // Por ejemplo, podrías emitir un evento o llamar a una función de prop para recargar los datos del cliente
                // Luego, cerrar el popup después de un breve retraso
                setTimeout(() => {
                    togglePopup();
                }, 1000);
            } catch (err) {
                setError(err.message || 'Error al crear la recompensa.');
            }
        } else {
            // Si la acción no es 'add', simplemente cerrar el popup
            togglePopup();
        }
    };

    return (
        <>
            {/* Popup */}
            <div className="popup-overlay" onClick={togglePopup}>
                <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                    <div className="popup-header">
                        <h3>Adjust Points</h3>
                        <button className="popup-close" onClick={togglePopup}>
                            &times;
                        </button>
                    </div>
                    <hr />
                    <form className="popup-body" onSubmit={handleSubmit}>
                        <label htmlFor="action-select">Select action</label>
                        <select
                            id="action-select"
                            className="popup-input"
                            value={action}
                            onChange={(e) => setAction(e.target.value)}
                            required
                        >
                            <option value="add">Add points</option>
                            <option value="remove">Remove points</option>
                        </select>

                        {action === 'add' && (
                            <>
                                <label htmlFor="points-input">Number of points to adjust</label>
                                <input
                                    type="number"
                                    id="points-input"
                                    className="popup-input"
                                    placeholder="Enter points"
                                    min="1"
                                    value={points}
                                    onChange={(e) => setPoints(e.target.value)}
                                    required
                                />

                                <label htmlFor="history-entry">Reason / History Entry</label>
                                <input
                                    type="text"
                                    id="history-entry"
                                    className="popup-input"
                                    placeholder="Manual Adjustment"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    required
                                />
                            </>
                        )}

                        {/* Mostrar mensajes de error o éxito */}
                        {error && <p className="popup-error">{error}</p>}
                        {success && <p className="popup-success">{success}</p>}

                        <div className="popup-footer">
                            <button type="submit" className="popup-submit">
                                Make Adjustment
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default AdjustPointsPopup;
