import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import '../style/pages/Login.css';
import logo from "../assets/logo.png";

function Login() {
    const API_URL = process.env.REACT_APP_API_URL;
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${API_URL}/auth/login`, {
                username,
                password,
            });

            localStorage.setItem("access_token", response.data.access_token);
            navigate("/customers");

        } catch (err) {
            setError("Invalid Username  or Password");
        }
    };

    return (
        <div className="login-container">
            <form className="login-form" onSubmit={handleLogin}>
                <div className="form-logo">
                    <img src={logo} alt="Logo" />
                </div>
                <h2>Mardo Rewards Software</h2>
                {error && <p className="error">{error}</p>}
                <div className="form-group">
                    <label>Username</label>
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Password</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit">Login</button>
            </form>
        </div>
    );
}

export default Login;