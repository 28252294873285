// src/components/RedemptionHistoryTable.js

import React from 'react';

function RedemptionHistoryTable({
  redemptionHistory,
  redemptionLoading,
  redemptionError,
  redemptionTotal,
  redemptionLimit,
  redemptionPage,
  redemptionTotalPages,
  handleRedemptionPageChange,
}) {
  // Generar filas de placeholder durante la carga
  const renderPlaceholderRows = () => {
    const placeholders = [];
    for (let i = 0; i < redemptionLimit; i++) {
      placeholders.push(
        <tr key={`placeholder-${i}`} className="custdash-placeholder-row">
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
        </tr>
      );
    }
    return placeholders;
  };

  return (
    <>
      <table className="custdash-table">
        <thead>
          <tr>
            <th>Reward</th>
            <th>Code</th>
            <th>Order Id</th>
            <th>Points Used</th>
            <th>Redeemed At</th>
            <th>Status</th>
            <th>Expires</th>
          </tr>
        </thead>
        <tbody>
          {redemptionLoading ? (
            renderPlaceholderRows()
          ) : redemptionError ? (
            <tr>
              <td colSpan="7" className="custdash-error">
                Error: {redemptionError}
              </td>
            </tr>
          ) : redemptionHistory.length > 0 ? (
            redemptionHistory.map((entry, index) => (
              <tr key={index}>
                <td>{entry.Reward}</td>
                <td>{entry.Code}</td>
                <td>{entry['Order Id']}</td>
                <td>{entry['Points Used']}</td>
                <td>{new Date(entry['Redeemed At']).toISOString().split('T')[0]}</td>
                <td>{entry.Status}</td>
                <td>{entry.Expires}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7" className="custdash-no-data">
                No redemption history available.
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {/* Paginación para Redemption History */}
      {redemptionTotal > redemptionLimit && (
        <div className="custdash-pagination">
          <button
            disabled={redemptionPage === 1}
            onClick={() => handleRedemptionPageChange(redemptionPage - 1)}
          >
            Previous
          </button>
          <span>
            Page {redemptionPage} of {redemptionTotalPages}
          </span>
          <button
            disabled={redemptionPage === redemptionTotalPages}
            onClick={() => handleRedemptionPageChange(redemptionPage + 1)}
          >
            Next
          </button>
        </div>
      )}
    </>
  );
}

export default React.memo(RedemptionHistoryTable);
