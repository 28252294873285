// src/components/CustomerCard.js

import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom'; // Para obtener customer_id
import { FaUserCircle } from 'react-icons/fa';
import '../style/pages/CustomerCard.css';

import EarningHistoryTable from '../components/CustomerCard/EarningHistoryTable';
import RedemptionHistoryTable from '../components/CustomerCard/RedemptionHistoryTable';
import AdjustPointsPopup from '../components/CustomerCard/AdjustPointsPopup';

function CustomerCard() {
  const API_URL = process.env.REACT_APP_API_URL;
  const { id: customer_id } = useParams(); // Obtener customer_id de los parámetros de la ruta

  // Estado para el popup
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  // Función para alternar el popup
  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  // Estado para los datos del cliente
  const [customerData, setCustomerData] = useState(null);
  const [customerLoading, setCustomerLoading] = useState(true);
  const [customerError, setCustomerError] = useState(null);

  // Estados para Earning History
  const [earningHistory, setEarningHistory] = useState([]);
  const [earningTotal, setEarningTotal] = useState(0);
  const [earningPage, setEarningPage] = useState(1);
  const earningLimit = 10; // Límite fijo de 10 por página
  const [earningLoading, setEarningLoading] = useState(false);
  const [earningError, setEarningError] = useState(null);

  // Estados para Redemption History
  const [redemptionHistory, setRedemptionHistory] = useState([]);
  const [redemptionTotal, setRedemptionTotal] = useState(0);
  const [redemptionPage, setRedemptionPage] = useState(1);
  const redemptionLimit = 10; // Límite fijo de 10 por página
  const [redemptionLoading, setRedemptionLoading] = useState(false);
  const [redemptionError, setRedemptionError] = useState(null);

  // Refs para las secciones de las tablas
  const earningSectionRef = useRef(null);
  const redemptionSectionRef = useRef(null);

  // Fetch customer data
  useEffect(() => {
    const fetchCustomerData = async () => {
      setCustomerLoading(true);
      setCustomerError(null);
      try {
        const response = await fetch(`${API_URL}/system_customers/customer/${customer_id}`);
        if (!response.ok) {
          throw new Error(`Error fetching customer data: ${response.statusText}`);
        }
        const data = await response.json();
        setCustomerData(data);
      } catch (error) {
        console.error("Error fetching customer data:", error);
        setCustomerError(error.message);
      }
      setCustomerLoading(false);
    };

    fetchCustomerData();
  }, [API_URL, customer_id]);

  // Fetch Earning History
  useEffect(() => {
    const fetchEarningHistory = async () => {
      setEarningLoading(true);
      setEarningError(null);
      try {
        const response = await fetch(
          `${API_URL}/system_customers/customer/${customer_id}/earning_history?page=${earningPage}&limit=${earningLimit}`
        );
        if (!response.ok) {
          throw new Error(`Error fetching earning history: ${response.statusText}`);
        }
        const data = await response.json();
        setEarningHistory(data.data);
        setEarningTotal(data.total);
      } catch (error) {
        console.error("Error fetching earning history:", error);
        setEarningError(error.message);
      }
      setEarningLoading(false);
    };

    fetchEarningHistory();
  }, [API_URL, customer_id, earningPage]);

  // Fetch Redemption History
  useEffect(() => {
    const fetchRedemptionHistory = async () => {
      setRedemptionLoading(true);
      setRedemptionError(null);
      try {
        const response = await fetch(
          `${API_URL}/system_customers/customer/${customer_id}/redemption_history?page=${redemptionPage}&limit=${redemptionLimit}`
        );
        if (!response.ok) {
          throw new Error(`Error fetching redemption history: ${response.statusText}`);
        }
        const data = await response.json();
        setRedemptionHistory(data.data);
        setRedemptionTotal(data.total);
      } catch (error) {
        console.error("Error fetching redemption history:", error);
        setRedemptionError(error.message);
      }
      setRedemptionLoading(false);
    };

    fetchRedemptionHistory();
  }, [API_URL, customer_id, redemptionPage]);

  // Sidebar items
  const sidebarItems = customerData
    ? [
      { label: 'LOYALTY ELIGIBILITY', value: customerData.eligible_status },
      { label: 'Eligible since', value: customerData.eligible_since },
      { label: 'CURRENT POINTS BALANCE', value: `${customerData.points} points` },
      { label: 'TOTAL POINTS EARNED', value: `${customerData.points_earned} points` },
      { label: 'TOTAL SPEND', value: `$${customerData.total_spend}` },
      { label: 'POINTS EXPIRE', value: customerData.points_expire },
    ]
    : [];

  // Calculate total pages for Earning History
  const earningTotalPages = Math.ceil(earningTotal / earningLimit);

  // Handle page change for Earning History
  const handleEarningPageChange = (newPage) => {
    if (newPage >= 1 && newPage <= earningTotalPages) {
      setEarningPage(newPage);
      // Scroll to the Earning History section
      earningSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Calculate total pages for Redemption History
  const redemptionTotalPages = Math.ceil(redemptionTotal / redemptionLimit);

  // Handle page change for Redemption History
  const handleRedemptionPageChange = (newPage) => {
    if (newPage >= 1 && newPage <= redemptionTotalPages) {
      setRedemptionPage(newPage);
      // Scroll to the Redemption History section
      redemptionSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="custdash-container">
      <div className="custdash-sidebar">
        <div className="custdash-sidebar-header">
          <FaUserCircle className="custdash-user-icon" />
          <h4 className="custdash-user-name">
            {customerLoading ? 'Loading...' : customerData ? customerData.name : 'N/A'}
          </h4>
          <p className="custdash-user-email">
            {customerLoading ? '' : customerData ? customerData.email : 'N/A'}
          </p>
        </div>
        <ul className="custdash-sidebar-list">
          {sidebarItems.length > 0 ? (
            sidebarItems.map((item, index) => (
              <li key={index} className="custdash-sidebar-item">
                <strong className="custdash-sidebar-label">{item.label}</strong>
                <p className="custdash-sidebar-value">{item.value}</p>
              </li>
            ))
          ) : (
            !customerLoading && <p>No customer data available.</p>
          )}

          {/* Botón que abre el popup */}
          <button className="custdash-button" onClick={togglePopup}>
            Adjust Points
          </button>
        </ul>
      </div>


      {/* Ajustamos el popup para que use el estado del padre */}
      {isPopupOpen && <AdjustPointsPopup togglePopup={togglePopup} customerId={customer_id} />}

      <div className="custdash-main-content">
        {/* EARNING HISTORY TABLE */}
        {customerError && (
          <div className="error-message">
            <p>Error: {customerError}</p>
          </div>
        )}

        {/* EARNING HISTORY TABLE */}
        <div className="custdash-table-section" ref={earningSectionRef}>
          <h5 className="custdash-table-title">EARNING HISTORY</h5>
          <EarningHistoryTable
            earningHistory={earningHistory}
            earningLoading={earningLoading}
            earningError={earningError}
            earningTotal={earningTotal}
            earningLimit={earningLimit}
            earningPage={earningPage}
            earningTotalPages={earningTotalPages}
            handleEarningPageChange={handleEarningPageChange}
          />
        </div>

        {/* REDEMPTION HISTORY */}
        <div className="custdash-table-section" ref={redemptionSectionRef}>
          <h5 className="custdash-table-title">REDEMPTION HISTORY</h5>
          <RedemptionHistoryTable
            redemptionHistory={redemptionHistory}
            redemptionLoading={redemptionLoading}
            redemptionError={redemptionError}
            redemptionTotal={redemptionTotal}
            redemptionLimit={redemptionLimit}
            redemptionPage={redemptionPage}
            redemptionTotalPages={redemptionTotalPages}
            handleRedemptionPageChange={handleRedemptionPageChange}
          />
        </div>

        {/* PURCHASE HISTORY */}
        <div className="custdash-table-section">
          <h5 className="custdash-table-title">PURCHASE HISTORY</h5>
          {/* Mantén la estructura existente o actualízala según sea necesario */}
        </div>

        {/* EMAIL HISTORY */}
        <div className="custdash-table-section">
          <h5 className="custdash-table-title">EMAIL HISTORY</h5>
          {/* Mantén la estructura existente o actualízala según sea necesario */}
        </div>
      </div>
    </div>
  );
}

export default CustomerCard;
