// src/components/EarningHistoryTable.js

import React from 'react';

function EarningHistoryTable({
  earningHistory,
  earningLoading,
  earningError,
  earningTotal,
  earningLimit,
  earningPage,
  earningTotalPages,
  handleEarningPageChange,
}) {
  // Generar filas de placeholder durante la carga
  const renderPlaceholderRows = () => {
    const placeholders = [];
    for (let i = 0; i < earningLimit; i++) {
      placeholders.push(
        <tr key={`placeholder-${i}`} className="custdash-placeholder-row">
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
        </tr>
      );
    }
    return placeholders;
  };

  return (
    <>
      <table className="custdash-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Campaign Type</th>
            <th>Reward</th>
            <th>Status</th>
            <th>Points Expiration</th>
          </tr>
        </thead>
        <tbody>
          {earningLoading ? (
            renderPlaceholderRows()
          ) : earningError ? (
            <tr>
              <td colSpan="5" className="custdash-error">
                Error: {earningError}
              </td>
            </tr>
          ) : earningHistory.length > 0 ? (
            earningHistory.map((entry, index) => (
              <tr key={index}>
                <td>{entry.Date}</td>
                <td>{entry['Campaign Type']}</td>
                <td>{entry.Reward}</td>
                <td>{entry.Status}</td>
                <td>
                  {entry['Points Expiration']
                    ? new Date(entry['Points Expiration']).toISOString().split('T')[0]
                    : 'N/A'}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="custdash-no-data">
                No earning history available.
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {/* Paginación para Earning History */}
      {earningTotal > earningLimit && (
        <div className="custdash-pagination">
          <button
            disabled={earningPage === 1}
            onClick={() => handleEarningPageChange(earningPage - 1)}
          >
            Previous
          </button>
          <span>
            Page {earningPage} of {earningTotalPages}
          </span>
          <button
            disabled={earningPage === earningTotalPages}
            onClick={() => handleEarningPageChange(earningPage + 1)}
          >
            Next
          </button>
        </div>
      )}
    </>
  );
}

export default React.memo(EarningHistoryTable);
